import { VividplicPostSimple } from "../assets/Templates/dynamic/VividplicPostSimple";
import { VividplicStorySimple } from "../assets/Templates/dynamic/VividplicStorySimple";
import { VividplicStoryDetailed } from "../assets/Templates/dynamic/VividplicStoryDetailed";
import { VividplicPostDetailed } from "../assets/Templates/dynamic/VividplicPostDetailed";
import { ColvenStoryDetailed } from "../assets/Templates/dynamic/ColvenStoryDetailed";
import { ColvenStorySimple } from "../assets/Templates/dynamic/ColvenStorySimple";
import { ColvenPostDetailed } from "../assets/Templates/dynamic/ColvenPostDetailed";
import { ColvenPostSimple } from "../assets/Templates/dynamic/ColvenPostSimple";
import { ColdancePostSimple } from "../assets/Templates/dynamic/ColdancePostSimple";
import { ColdanceStorySimple } from "../assets/Templates/dynamic/ColdanceStorySimple";
import { CalperStoryDetailed } from "../assets/Templates/dynamic/CalperStoryDetailed";
import { CalperStorySimple } from "../assets/Templates/dynamic/CalperStorySimple";
import { CalperPostDetailed } from "../assets/Templates/dynamic/CalperPostDetailed";
import { CalperPostSimple } from "../assets/Templates/dynamic/CalperPostSimple";
import { WorloyStoryDetailed } from "../assets/Templates/dynamic/WorloyStoryDetailed";
import { WorloyPostDetailed } from "../assets/Templates/dynamic/WorloyPostDetailed";
import { WorloyStorySimple } from "../assets/Templates/dynamic/WorloyStorySimple";
import { WorloyPostSimple } from "../assets/Templates/dynamic/WorloyPostSimple";
import { ContrachapStoryDetailed } from "../assets/Templates/dynamic/ContrachapStoryDetailed";
import { ContrachapPostDetailed } from "../assets/Templates/dynamic/ContrachapPostDetailed";
import { ContrachapPostSimple } from "../assets/Templates/dynamic/ContrachapPostSimple";
import { ContrachapStorySimple } from "../assets/Templates/dynamic/ContrachapStorySimple";
import { LiteentStoryDetailed } from "../assets/Templates/dynamic/LiteentStoryDetailed";
import { LiteentPostDetailed } from "../assets/Templates/dynamic/LiteentPostDetailed";
import { LiteentPostSimple } from "../assets/Templates/dynamic/LiteentPostSimple";
import { LiteentStorySimple } from "../assets/Templates/dynamic/LiteentStorySimple";
import { RivblisStorySimple } from "../assets/Templates/dynamic/RivblisStorySimple";
import { RivblisPostSimple } from "../assets/Templates/dynamic/RivblisPostSimple";
import { PlayfulStorySimple } from "../assets/Templates/dynamic/PlayfulStorySimple";
import { PlayfulPostSimple } from "../assets/Templates/dynamic/PlayfulPostSimple";
import { MilestonePostSimple } from "../assets/Templates/dynamic/MilestonePostSimple";
import { MilestoneStorySimple } from "../assets/Templates/dynamic/MilestoneStorySimple";
import { IngparPostDetailed } from "../assets/Templates/dynamic/IngparPostDetailed";
import { IngparStoryDetailed } from "../assets/Templates/dynamic/IngparStoryDetailed";
import { TingthiStorySimple } from "../assets/Templates/dynamic/TingthiStorySimple";
import { TingthiPostSimple } from "../assets/Templates/dynamic/TingthiPostSimple";
import { ThiverPostSimple } from "../assets/Templates/dynamic/ThiverPostSimple";
import { ThiverStorySimple } from "../assets/Templates/dynamic/ThiverStorySimple";

import { TopLeft } from "../assets/Stickers/dynamic/TopLeft";
import { TopRight } from "../assets/Stickers/dynamic/TopRight";
import { Dialogue } from "../assets/Stickers/dynamic/Dialogue";
import { TopLeftCut } from "../assets/Stickers/dynamic/TopLeftCut";
import { RightRibbon } from "../assets/Stickers/dynamic/RightRibbon";
import { TopRibbon } from "../assets/Stickers/dynamic/TopRibbon";
import { RightPointer } from "../assets/Stickers/dynamic/RightPointer";

export const header_variants = {
  desktop__right_cta: "desktop right_cta",
  mobile: "mobile",
};

export const accordionStatusColors = {
  done: "#00B779",
  not_done: "#CFCDC9",
};

export const editPageStagesKeys = {
  template_selection: "template_selection",
  template_edit: "template_edit",
};

export const editingSteps = [
  {
    title: "Choose a template",
  },
  {
    title: "Customize the design",
  },
  {
    title: "Download the design",
  },
];

export const marketing_creatives_item_data_session_storage_key =
  "marketing_creatives__item_data";

export const creative_types = { post: 1, story: 2, both: 3 };

export const creative_types_height = {
  [creative_types.post]: 1080,
  [creative_types.story]: 1920,
};

export const getCreativeUuidFromSessionData = () =>
  getMarketingCreativeEditSessionData()?.creative_uuid;

export const getCreativeHeight = (creative_type) =>
  creative_types_height[creative_type];

export const getDefaultStickerPosition = (creative_type) => ({
  x: 0,
  y: -creative_types_height[creative_type],
});

export const creative_detail_types = { simple: "simple", detailed: "detailed" };

/**
 * MarketingCreativeEditSessionData = { // (inside updated_values as creative_data)
 *      is_active (inside updated_values)
 *      creative_uuid (as uuid in get list and details api)
 *      creative_status (inside updated_values)
 *      listing_uuid
 *      creative_type (inside updated_values)
 *      creative_detail_type
 *      template_name (inside updated_values as template_name)
 *      text_data: {
 *          heading
 *          sub_heading
 *          start_date_time: {
 *              exists:
 *              date:
 *              time:
 *          }
 *          end_date_time: {
 *              exists:
 *              date:
 *              time:
 *          }
 *          action_text: values?.action_text,
 *          name: values?.name,
 *          designation: values?.designation,
 *          org_name: values?.org_name,
 *          additional_text: values?.additional_text,
 *          details_list: {
 *              title:
 *              data: []
 *          }
 *      }
 *      cover_image
 *      sticker: {
 *          name:
 *          position: {
 *              post: {x: , y: },
 *              story: {x: , y: }
 *          }
 *          text:
 *          color:
 *      }
 *      colors: []
 * };
 */

export const convertCreativeSessionDataToPayload = (data) => {
  return {
    creative_uuid: data?.creative_uuid,
    updated_values: {
      creative_data: data,
      creative_status: data?.creative_status,
      creative_type: data?.creative_type,
    },
  };
};

export const convertPayloadToCreativeSessionData = (data) => {
  return {
    ...data?.creative_data,
    creative_uuid: data?.uuid,
    tracking_link: data?.tracking_link,
    listing_title: data?.listing_title,
    creative_status: data?.creative_status,
    template_name: data?.template_name,
    creative_type: data?.creative_type,
  };
};

export const setMarketingCreativeEditSessionData = (data) => {
  sessionStorage.setItem(
    marketing_creatives_item_data_session_storage_key,
    JSON.stringify(data)
  );
};

export const getMarketingCreativeEditSessionData = () => {
  return JSON.parse(
    sessionStorage.getItem(marketing_creatives_item_data_session_storage_key)
  );
};

export const marketingCreativeTemplateKeys = {
  Calper: "Calper",
  Coldance: "Coldance",
  Colven: "Colven",
  Contrachap: "Contrachap",
  Ingpar: "Ingpar",
  Liteent: "Liteent",
  Milestone: "Milestone",
  Playful: "Playful",
  Rivblis: "Rivblis",
  Thiver: "Thiver",
  Tingthi: "Tingthi",
  Vividplic: "Vividplic",
  Worloy: "Worloy",
};

export const marketingCreativeColorSchemes = {
  [marketingCreativeTemplateKeys.Calper]: {
    schemes: [
      ["#1B1B1B", "#CF2E3F", "#FFFFFF"],
      ["#001845", "#FF784F", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Coldance]: {
    schemes: [
      ["#498C78", "#1A4338", "#FFFFFF"],
      ["#650000", "#2F0000", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Colven]: {
    schemes: [
      ["#FFBD26", "#2E0A37", "#DB3A34", "#FFFFFF"],
      ["#00CDAB", "#2E0A37", "#FFD23F", "#FFFFFF"],
    ],
    count: 4,
  },
  [marketingCreativeTemplateKeys.Contrachap]: {
    schemes: [
      ["#7B2CBF", "#FFD425", "#FFFFFF"],
      ["#EA698B", "#FFD425", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Ingpar]: {
    schemes: [
      ["#EEEEEE", "#A5A5A5", "#050505"],
      ["#FEFAE0", "#D4A373", "#050505"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Liteent]: {
    schemes: [
      ["#69B578", "#19331E", "#FFFFFF"],
      ["#8979B9", "#201A32", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Milestone]: {
    schemes: [
      ["#8BD2BD", "#A8DADC", "#F1FAEE", "#1D3557"],
      ["#EF233C", "#23233D", "#4A4E69", "#FFFFFF"],
    ],
    count: 4,
  },
  [marketingCreativeTemplateKeys.Playful]: {
    schemes: [
      ["#FBCC00", "#222222", "#FFFFFF"],
      ["#A2D2FF", "#222222", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Rivblis]: {
    schemes: [
      ["#023C40", "#AB927D", "#FFFFFF"],
      ["#632627", "#AB927D", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Thiver]: {
    schemes: [
      ["#656F7C", "#F2E94E", "#778290"],
      ["#003049", "#21FA90", "#496574"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Tingthi]: {
    schemes: [
      ["#000000", "#FF5757", "#FFFFFF"],
      ["#011936", "#0A85ED", "#FFFFFF"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Vividplic]: {
    schemes: [
      ["#463EBC", "#E74B69", "#FFFFFF"],
      ["#5398BE", "#F2CD5D", "#2E0A37"],
    ],
    count: 3,
  },
  [marketingCreativeTemplateKeys.Worloy]: {
    schemes: [
      ["#B214AE", "#01A8B2", "#F26419", "#000000"],
      ["#89FC00", "#AA075F", "#99E1D9", "#351431"],
    ],
    count: 4,
  },
};

export const basicInputFieldKeys = {
  heading: "heading",
  sub_heading: "sub_heading",
  date_time: "date_time",
  action_text: "action_text",
  name: "name",
  designation: "designation",
  org_name: "org_name",
  additional_text: "additional_text",
  details_list_title: "details_list_title",
  details_list: "details_list",
  cover_image: "cover_image",
};

export const dateTimeInputFieldKeys = {
  start_date: "start_date",
  end_date: "end_date",
  start_time: "start_time",
  end_time: "end_time",
};

export const marketingCreativeTemplates = {
  [marketingCreativeTemplateKeys.Calper]: {
    post: {
      simple: {
        name: "CalperPostSimple",
        image: "CalperPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Calper,
        creative_detail_type: creative_detail_types.simple,
        component: CalperPostSimple,
        cover_image_aspect: 10 / 27,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.date_time]: {},
        },
      },
      detailed: {
        name: "CalperPostDetailed",
        image: "CalperPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Calper,
        creative_detail_type: creative_detail_types.detailed,
        component: CalperPostDetailed,
        cover_image_aspect: 10 / 27,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "CalperStorySimple",
        image: "CalperStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Calper,
        creative_detail_type: creative_detail_types.simple,
        component: CalperStorySimple,
        cover_image_aspect: 10 / 27,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.date_time]: {},
        },
      },
      detailed: {
        name: "CalperStoryDetailed",
        image: "CalperStoryDetailed.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Calper,
        creative_detail_type: creative_detail_types.detailed,
        component: CalperStoryDetailed,
        cover_image_aspect: 10 / 27,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Coldance]: {
    post: {
      simple: {
        name: "ColdancePostSimple",
        image: "ColdancePostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Coldance,
        creative_detail_type: creative_detail_types.simple,
        component: ColdancePostSimple,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
        },
      },
    },
    story: {
      simple: {
        name: "ColdanceStorySimple",
        image: "ColdanceStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Coldance,
        creative_detail_type: creative_detail_types.simple,
        component: ColdanceStorySimple,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Colven]: {
    post: {
      simple: {
        name: "ColvenPostSimple",
        image: "ColvenPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Colven,
        creative_detail_type: creative_detail_types.simple,
        component: ColvenPostSimple,
        cover_image_aspect: 610 / 764,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "ColvenPostDetailed",
        image: "ColvenPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Colven,
        creative_detail_type: creative_detail_types.detailed,
        component: ColvenPostDetailed,
        cover_image_aspect: 610 / 764,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "ColvenStorySimple",
        image: "ColvenStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Colven,
        creative_detail_type: creative_detail_types.simple,
        component: ColvenStorySimple,
        cover_image_aspect: 610 / 764,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "ColvenStoryDetailed",
        image: "ColvenStoryDetailed.png",
        template_name: marketingCreativeTemplateKeys.Colven,
        creative_detail_type: creative_detail_types.detailed,
        component: ColvenStoryDetailed,
        cover_image_aspect: 610 / 764,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Contrachap]: {
    post: {
      simple: {
        name: "ContrachapPostSimple",
        image: "ContrachapPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Contrachap,
        creative_detail_type: creative_detail_types.simple,
        component: ContrachapPostSimple,
        cover_image_aspect: 47 / 69,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "ContrachapPostDetailed",
        image: "ContrachapPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Contrachap,
        creative_detail_type: creative_detail_types.detailed,
        component: ContrachapPostDetailed,
        cover_image_aspect: 47 / 69,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "ContrachapStorySimple",
        image: "ContrachapStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Contrachap,
        creative_detail_type: creative_detail_types.simple,
        component: ContrachapStorySimple,
        cover_image_aspect: 47 / 69,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "ContrachapStoryDetailed",
        image: "ContrachapStoryDetailed.png",
        template_name: marketingCreativeTemplateKeys.Contrachap,
        creative_detail_type: creative_detail_types.detailed,
        component: ContrachapStoryDetailed,
        cover_image_aspect: 47 / 69,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Ingpar]: {
    post: {
      detailed: {
        name: "IngparPostDetailed",
        image: "IngparPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Ingpar,
        creative_detail_type: creative_detail_types.detailed,
        component: IngparPostDetailed,
        cover_image_aspect: 27 / 26,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list]: { required: true },
        },
      },
    },
    story: {
      detailed: {
        name: "IngparStoryDetailed",
        image: "IngparStoryDetailed.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Ingpar,
        creative_detail_type: creative_detail_types.detailed,
        component: IngparStoryDetailed,
        cover_image_aspect: 27 / 26,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list]: { required: true },
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Liteent]: {
    post: {
      simple: {
        name: "LiteentPostSimple",
        image: "LiteentPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Liteent,
        creative_detail_type: creative_detail_types.simple,
        component: LiteentPostSimple,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "LiteentPostDetailed",
        image: "LiteentPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Liteent,
        creative_detail_type: creative_detail_types.detailed,
        component: LiteentPostDetailed,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "LiteentStorySimple",
        image: "LiteentStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Liteent,
        creative_detail_type: creative_detail_types.simple,
        component: LiteentStorySimple,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "LiteentStoryDetailed",
        image: "LiteentStoryDetailed.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Liteent,
        creative_detail_type: creative_detail_types.detailed,
        component: LiteentStoryDetailed,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Milestone]: {
    post: {
      simple: {
        name: "MilestonePostSimple",
        image: "MilestonePostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Milestone,
        creative_detail_type: creative_detail_types.simple,
        component: MilestonePostSimple,
        cover_image_aspect: 223 / 403,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
    story: {
      simple: {
        name: "MilestoneStorySimple",
        image: "MilestoneStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Milestone,
        creative_detail_type: creative_detail_types.simple,
        component: MilestoneStorySimple,
        cover_image_aspect: 223 / 403,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Playful]: {
    post: {
      simple: {
        name: "PlayfulPostSimple",
        image: "PlayfulPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Playful,
        creative_detail_type: creative_detail_types.simple,
        component: PlayfulPostSimple,
        cover_image_aspect: 223 / 342,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
    story: {
      simple: {
        name: "PlayfulStorySimple",
        image: "PlayfulStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Playful,
        creative_detail_type: creative_detail_types.simple,
        component: PlayfulStorySimple,
        cover_image_aspect: 223 / 342,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Rivblis]: {
    post: {
      simple: {
        name: "RivblisPostSimple",
        image: "RivblisPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Rivblis,
        creative_detail_type: creative_detail_types.simple,
        component: RivblisPostSimple,
        cover_image_aspect: 625 / 1092,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
    story: {
      simple: {
        name: "RivblisStorySimple",
        image: "RivblisStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Rivblis,
        creative_detail_type: creative_detail_types.simple,
        component: RivblisStorySimple,
        cover_image_aspect: 625 / 1092,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Thiver]: {
    post: {
      simple: {
        name: "ThiverPostSimple",
        image: "ThiverPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Thiver,
        creative_detail_type: creative_detail_types.simple,
        component: ThiverPostSimple,
        cover_image_aspect: 551 / 1250,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: { required: true },
        },
      },
    },
    story: {
      simple: {
        name: "ThiverStorySimple",
        image: "ThiverStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Thiver,
        creative_detail_type: creative_detail_types.simple,
        component: ThiverStorySimple,
        cover_image_aspect: 551 / 1250,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: { required: true },
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Tingthi]: {
    post: {
      simple: {
        name: "TingthiPostSimple",
        image: "TingthiPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Tingthi,
        creative_detail_type: creative_detail_types.simple,
        component: TingthiPostSimple,
        cover_image_aspect: 613 / 768,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
    story: {
      simple: {
        name: "TingthiStorySimple",
        image: "TingthiStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Tingthi,
        creative_detail_type: creative_detail_types.simple,
        component: TingthiStorySimple,
        cover_image_aspect: 613 / 768,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: { required: true },
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Vividplic]: {
    post: {
      simple: {
        name: "VividplicPostSimple",
        image: "VividplicPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Vividplic,
        creative_detail_type: creative_detail_types.simple,
        component: VividplicPostSimple,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
        },
      },
      detailed: {
        name: "VividplicPostDetailed",
        image: "VividplicPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Vividplic,
        creative_detail_type: creative_detail_types.detailed,
        component: VividplicPostDetailed,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "VividplicStorySimple",
        image: "VividplicStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Vividplic,
        creative_detail_type: creative_detail_types.simple,
        component: VividplicStorySimple,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
        },
      },
      detailed: {
        name: "VividplicStoryDetailed",
        image: "VividplicStoryDetailed.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Vividplic,
        creative_detail_type: creative_detail_types.detailed,
        component: VividplicStoryDetailed,
        cover_image_aspect: 1 / 1,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.name]: {},
          [basicInputFieldKeys.designation]: {},
          [basicInputFieldKeys.org_name]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },

  [marketingCreativeTemplateKeys.Worloy]: {
    post: {
      simple: {
        name: "WorloyPostSimple",
        image: "WorloyPostSimple.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Worloy,
        creative_detail_type: creative_detail_types.simple,
        component: WorloyPostSimple,
        cover_image_aspect: 502 / 1003,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "WorloyPostDetailed",
        image: "WorloyPostDetailed.png",
        creative_type: creative_types.post,
        template_name: marketingCreativeTemplateKeys.Worloy,
        creative_detail_type: creative_detail_types.detailed,
        component: WorloyPostDetailed,
        cover_image_aspect: 502 / 1003,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
    story: {
      simple: {
        name: "WorloyStorySimple",
        image: "WorloyStorySimple.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Worloy,
        creative_detail_type: creative_detail_types.simple,
        component: WorloyStorySimple,
        cover_image_aspect: 502 / 1003,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
        },
      },
      detailed: {
        name: "WorloyStoryDetailed",
        image: "WorloyStoryDetailed.png",
        creative_type: creative_types.story,
        template_name: marketingCreativeTemplateKeys.Worloy,
        creative_detail_type: creative_detail_types.detailed,
        component: WorloyStoryDetailed,
        cover_image_aspect: 502 / 1003,
        input_fields: {
          [basicInputFieldKeys.heading]: { required: true },
          [basicInputFieldKeys.sub_heading]: {},
          [basicInputFieldKeys.date_time]: {},
          [basicInputFieldKeys.cover_image]: { required: true },
          [basicInputFieldKeys.additional_text]: {},
          [basicInputFieldKeys.action_text]: {},
          [basicInputFieldKeys.details_list_title]: {},
          [basicInputFieldKeys.details_list]: {},
        },
      },
    },
  },
};

export const storySimpleMarketingCreativeTemplates = Object.values(
  marketingCreativeTemplates
)
  .filter((t) => t?.story?.simple)
  .map((t) => t?.story?.simple);

export const postSimpleMarketingCreativeTemplates = Object.values(
  marketingCreativeTemplates
)
  .filter((t) => t?.post?.simple)
  .map((t) => t?.post?.simple);

export const storyDetailedMarketingCreativeTemplates = Object.values(
  marketingCreativeTemplates
)
  .filter((t) => t?.story?.detailed)
  .map((t) => t?.story?.detailed);

export const postDetailedMarketingCreativeTemplates = Object.values(
  marketingCreativeTemplates
)
  .filter((t) => t?.post?.detailed)
  .map((t) => t?.post?.detailed);

export const stickerDefaultColor = "#D64242";

export const marketingCreativeStickersKeys = {
  TopLeft: "TopLeft",
  TopRight: "TopRight",
  Dialogue: "Dialogue",
  TopLeftCut: "TopLeftCut",
  RightRibbon: "RightRibbon",
  TopRibbon: "TopRibbon",
  RightPointer: "RightPointer",
};

export const marketingCreativeStickers = {
  [marketingCreativeStickersKeys.TopLeft]: {
    component: TopLeft,
    name: marketingCreativeStickersKeys.TopLeft,
    image: "TopLeft.png",
    default_text: "50% Off!",
  },
  [marketingCreativeStickersKeys.TopRight]: {
    component: TopRight,
    name: marketingCreativeStickersKeys.TopRight,
    image: "TopRight.png",
    default_text: "50% Off!",
  },
  [marketingCreativeStickersKeys.Dialogue]: {
    component: Dialogue,
    name: marketingCreativeStickersKeys.Dialogue,
    image: "Dialogue.png",
    default_text: "Admissions Open",
  },
  [marketingCreativeStickersKeys.TopLeftCut]: {
    component: TopLeftCut,
    name: marketingCreativeStickersKeys.TopLeftCut,
    image: "TopLeftCut.png",
    default_text: "Coming Soon",
  },
  [marketingCreativeStickersKeys.RightRibbon]: {
    component: RightRibbon,
    name: marketingCreativeStickersKeys.RightRibbon,
    image: "RightRibbon.png",
    default_text: "Grab your seats",
  },
  [marketingCreativeStickersKeys.TopRibbon]: {
    component: TopRibbon,
    name: marketingCreativeStickersKeys.TopRibbon,
    image: "TopRibbon.png",
    default_text: "Coming Soon",
  },
  [marketingCreativeStickersKeys.RightPointer]: {
    component: RightPointer,
    name: marketingCreativeStickersKeys.RightPointer,
    image: "RightPointer.png",
    default_text: "Join Now!",
  },
};

/**
 * as a workaround for issue font family not loading when using google fonts
 * https://github.com/tsayen/dom-to-image/issues/59
 * we manually load google font
 */
export const loadFont = (url) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4 && xhr.status === 200) {
      let css = xhr.responseText;
      css = css.replace(/}/g, "font-display: swap; }");

      const head = document.getElementsByTagName("head")[0];
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
    }
  };
  xhr.send();
};

export const MARKETING_CREATIVES_LEARN_MORE_HREFS = {
  MARKETING_CREATIVES:
    "https://support.exlyapp.com/support/solutions/articles/84000372464-design-marketing-creatives-within-5-minutes",
};
